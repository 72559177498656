.animated-text {
  animation: blink 1s step-start infinite; /* Adjust animation duration and timing function */
}

@keyframes blink {
  0%, 100% {
    visibility: visible;
  }
  10% {
    visibility: hidden;
  }
}

.gradient-button {
  background: linear-gradient(to right, #4CAF50, #2196F3) !important; /* Gradient colors */
}

.gradient-button:hover {
  background: linear-gradient(to left, #4CAF50, #2196F3) !important; /* Gradient colors on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2) !important; /* Shadow effect on hover */
}

html, body {
  overscroll-behavior-y: none; /* Disable pull-to-refresh */
}
